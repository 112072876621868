import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CompletedStatusIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#87B590"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3312 12.6881C11.1359 12.8833 10.8194 12.8833 10.6241 12.6881L9.06777 11.1317C8.8725 10.9365 8.55592 10.9365 8.36066 11.1317L7.65355 11.8388C7.45829 12.0341 7.45829 12.3507 7.65355 12.5459L10.6241 15.5165C10.8194 15.7117 11.1359 15.7117 11.3312 15.5165L16.8165 10.0312C17.0117 9.83594 17.0117 9.51936 16.8165 9.3241L16.1094 8.61699C15.9141 8.42173 15.5975 8.42173 15.4023 8.61699L11.3312 12.6881Z"
        fill="#264F2D"
      />
    </svg>
  );
};

export default CompletedStatusIcon;
