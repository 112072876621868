import React from "react";

import { useAuth } from "@contexts/auth";
import { InvoiceType } from "@lib/data/schemas/invoice";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import CompletedStatusIcon from "@components/Icons/CompletedStatusIcon";
import LoadingSpinner from "@components/LoadingSpinner";

interface PaymentsDashboardCardProps {
  className?: string;
  payments?: InvoiceType[];
  isStatic: boolean;
  isSelected: boolean;
  isLoading?: boolean;
}

const PaymentsDashboardCard: React.FC<PaymentsDashboardCardProps> = ({
  className,
  payments,
  isStatic,
  isSelected,
  isLoading,
}) => {
  const { uid, isOwner } = useAuth();

  let totalPaidInt: string | undefined;
  let totalPaidDec: string | undefined;
  if (payments && payments.length > 0) {
    const totalPaid = payments
      .filter((payment) => payment.status === "paid")
      .map((payment) => payment.total / 100)
      .reduce((a, b) => a + b, 0);
    const totalPaidFloor = Math.floor(totalPaid);
    totalPaidInt = totalPaidFloor.toLocaleString("en-US", {
      style: "currency",
      currency: payments[0].currency, // assuming the coach always uses the same currency
      maximumFractionDigits: 0, // removes decimals
      minimumFractionDigits: 0, // this line prevents a bug on some browsers
    });
    const totalPaidDecNumber =
      (Math.round((totalPaid - totalPaidFloor) * 1e2) / 1e2) * 100; // convert decimals to an integer
    totalPaidDec = totalPaidDecNumber.toLocaleString("en-US", {
      minimumIntegerDigits: 2, // shoudl have only 2 digits
      useGrouping: false,
    });
  } else if (payments && payments.length === 0) {
    totalPaidInt = "$0";
  }

  const printContent = () => {
    if (isLoading) return <LoadingSpinner variant="transparent" />;

    return (
      <>
        {totalPaidInt}
        {totalPaidDec && (
          <span className="text-grey-500 text-xl">.{totalPaidDec}</span>
        )}
      </>
    );
  };

  if (isStatic && !isLoading) {
    return (
      <StaticDashboardCard
        className={className}
        href={`/api/v1/users/${uid}/stripe/auth?platform=web`}
        title="Payments"
        number={3}
        description="Create and share your invoices with your clients."
        buttonText={isOwner ? "Link Stripe" : undefined}
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/services"
      title="Payments"
      content={printContent()}
      description="Total of paid invoices"
      DescriptionIcon={CompletedStatusIcon}
      isLoading={!totalPaidInt}
    />
  );
};

export default PaymentsDashboardCard;
