import { useCollection, useDocument } from "@contexts/data";
import { TaxTypeType } from "@lib/data/schemas/taxType";

export const useTaxTypes = (): { taxTypes: TaxTypeType[] } => {
  const { data } = useCollection("taxTypes", {
    baseCollection: true,
    orderByCreatedAt: false,
  });

  return { taxTypes: data ?? [] };
};

export const useTaxType = (
  id: string | undefined
): { taxType: TaxTypeType | undefined } => {
  const { data } = useDocument("taxTypes", id, {
    baseCollection: true,
    orderByCreatedAt: false,
  });

  return { taxType: data };
};
