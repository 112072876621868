import { FC, useMemo, useState } from "react";
import { updateUserOnboardingValues } from "api-services/definitions/user";
import {
  getWebflowVideos,
  VideoItemType,
} from "api-services/definitions/webflow";
import { api, useApiGet } from "api-services/endpoints";
import { differenceInMinutes } from "date-fns";

import { useAuth } from "@contexts/auth";
import { videoCategories } from "@lib/constants/webflow";

import { Button } from "@components/Button";
import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";
import VideoModal from "@components/Video/VideoModal/VideoModal";

import GetStartedVideoCard from "./GetStartedVideoCard";

interface GetStartedVideosSectionProps {
  className?: string;
}

const GetStartedVideosSection: FC<GetStartedVideosSectionProps> = ({
  className,
}) => {
  const { onboarding, aid } = useAuth();
  const [activeVideo, setActiveVideo] = useState<
    { video: VideoItemType; openedAt: number } | undefined
  >();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [dismissedVideos, setDismissedVideos] = useState<string[]>([]);

  const isLegacyUserAndShouldNotSeeVideos =
    typeof onboarding?.seenVideos === "undefined";

  const seenVideos = useMemo(
    () => [...(onboarding?.seenVideos ?? []), ...dismissedVideos],
    [dismissedVideos, onboarding?.seenVideos]
  );

  const { data: videos } = useApiGet(
    getWebflowVideos,
    {},
    { category: "get-started" },
    { revalidateOnFocus: false }
  );

  const category = videoCategories["get-started"];
  const videosToWatch = (videos ?? []).filter(
    (video) => !seenVideos.includes(video.id)
  );

  const handleDismissAll = async () => {
    if (!aid) return;

    const videoIds = videosToWatch.map((video) => video.id);

    setDismissedVideos([...dismissedVideos, ...videoIds]);

    await api(updateUserOnboardingValues, {
      path: { userId: aid },
      body: { seenVideos: videoIds },
    });
  };

  const dismissActiveVideo = async () => {
    if (!activeVideo) return;

    const minutesAgo = differenceInMinutes(Date.now(), activeVideo.openedAt);
    if (minutesAgo >= 1) {
      persistVideoDismissal(activeVideo.video.id);
    }

    setActiveVideo(undefined);
  };

  const persistVideoDismissal = async (videoId?: string) => {
    if (!aid || !videoId) return;

    setDismissedVideos([...dismissedVideos, videoId]);

    await api(updateUserOnboardingValues, {
      path: { userId: aid },
      body: { seenVideos: [videoId] },
    });
  };

  if (isLegacyUserAndShouldNotSeeVideos || videosToWatch.length === 0)
    return null;

  return (
    <div className={className}>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              <h2 className="text-xl">{category.title}</h2>
              <span className="py-1.5 px-2 text-sm font-medium leading-none text-white bg-peach-600 rounded-lg">
                {videosToWatch.length}
              </span>
            </div>
            <p className="text-sm text-grey-500">{category.description}</p>
          </div>
          <Button
            className="self-center"
            variant="default"
            small
            onClick={handleDismissAll}
          >
            Dismiss all
          </Button>
        </div>
        {videosToWatch
          ?.map((video) => (
            <GetStartedVideoCard
              key={video.id}
              title={video.name}
              description={video.description}
              thumbnailUrl={video.thumbnailUrl}
              onClickWatch={() =>
                setActiveVideo({ video, openedAt: Date.now() })
              }
              onClickDismiss={() => persistVideoDismissal(video.id)}
              length={video.length}
            />
          ))
          .slice(0, isCollapsed ? 1 : undefined)}
        {videosToWatch.length > 1 && (
          <div className="flex justify-center">
            <CollapsedHeader
              collapsed={isCollapsed}
              toggleCollapsed={setIsCollapsed}
              leftElement={<>Show {isCollapsed ? "more" : "less"}</>}
              className="text-grey-500 hover:text-black"
            />
          </div>
        )}
      </div>
      <VideoModal
        show={!!activeVideo}
        toggleShow={() => dismissActiveVideo()}
        autoplay
        videoUrl={activeVideo?.video.youtubeUrl}
      />
    </div>
  );
};

export default GetStartedVideosSection;
