import { FC } from "react";

interface DefaultFilterDescriptorProps {
  Icon: FC;
  type: "packages" | "labels" | "outcomes" | "schedulers";
}

const DefaultFilterDescriptor: FC<DefaultFilterDescriptorProps> = ({
  Icon,
  type,
}) => {
  return (
    <div className="flex items-center justify-center gap-1 text-grey-500">
      <Icon />
      <div>All {type}</div>
    </div>
  );
};

export default DefaultFilterDescriptor;
