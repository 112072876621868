import { FC, ReactNode } from "react";

import { OutcomeType } from "@lib/data/schemas/outcome";

import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";

import OutcomeIcon from "./OutcomeIcon";

interface OutcomePickerProps {
  onOutcomeChange: (value: OutcomeType) => void;
  outcomes: OutcomeType[];
  placeholder: string | ReactNode;
  value?: OutcomeType;
}

const OutcomePicker: FC<OutcomePickerProps> = ({
  onOutcomeChange,
  outcomes,
  placeholder,
  value,
}) => {
  const { filteredOptions: filteredLabels, searchProps } = useSimpleSearchProps(
    outcomes,
    "Search outcomes..."
  );

  return (
    <div className="shadow-none">
      <Listbox
        searchProps={searchProps}
        customMenuClassNames="!absolute !w-60 !mt-2"
        customListboxClassNames="!shadow-none"
        onChange={onOutcomeChange}
        label={null}
        placeholder={placeholder}
        value={value}
        customAddButton
        customMenuWrapperClassNames="bg-white"
      >
        <>
          {filteredLabels?.map((outcome, index) => {
            return (
              <ListboxOption
                key={`outcome-picker-item-${index}`}
                value={outcome}
              >
                <div className="flex gap-2">
                  <OutcomeIcon
                    outcome={outcome}
                    withLabel
                    iconClassName="w-4 h-4"
                    className="p-1"
                  />
                </div>
              </ListboxOption>
            );
          })}
        </>
      </Listbox>
    </div>
  );
};

export default OutcomePicker;
