import { FC, useState } from "react";
import axios from "axios";
import { compact } from "lodash";

import { useAuth } from "@contexts/auth";
import useEmailSignature from "@hooks/use-email-signature";
import useSnackbar from "@hooks/use-snackbar";
import { displayNameFromContact } from "@lib/contacts";
import { getCoachEmails } from "@lib/utils/email";

import { Button } from "@components/Button";
import { CustomOptionType } from "@components/Form/SelectForm/types";
import CloseIcon from "@components/Icons/CloseIcon";
import UnsavedChangesModal from "@components/Modals/UnsavedChangesModal";
import ModalWrappedForm from "@components/ModalWrappedForm";

import { ComposeEmail } from "./ComposeEmail";
import { SelectAliasesControlled } from "./SelectAliases";
import ToggleSignature from "./ToggleSignature";

interface EmailContentType {
  body: string;
  subject: string;
  ccEmails?: string;
  bccEmails?: string;
  bcc?: boolean;
  recipients: CustomOptionType[];
  fromEmail: string;
  toEmail?: string;
}

export const getFormattedEmails = (data: EmailContentType) => {
  const allValues = (data.recipients?.flat() || []).map((recipient) =>
    recipient?.value ? recipient.value : recipient
  );
  const values = compact(allValues);

  const emails = [...new Set(values)];
  let bccEmails;
  let ccEmails;
  let toEmail;
  if (emails?.length > 1) {
    if (data.bcc) bccEmails = emails.join(",");
    else ccEmails = emails.join(",");
  } else if (data.bcc) {
    bccEmails = emails.join(",");
  } else {
    toEmail = emails[0];
  }
  return { bccEmails, ccEmails, toEmail };
};

interface SendEmailModalProps {
  show: boolean;
  toggleShow: (val: boolean) => void;
  email?: string;
  clientIds?: string[];
  heapEventName?: string;
  groupId?: string;
  secondaryHeapEventName?: string;
  onEmailSent?: () => void;
}

const SendEmailModal: FC<SendEmailModalProps> = ({
  show,
  toggleShow,
  heapEventName,
  email,
  clientIds,
  groupId,
  secondaryHeapEventName,
  onEmailSent,
}) => {
  const snackbar = useSnackbar();
  const { account, oid, aid } = useAuth();
  const coachEmails = getCoachEmails(account);
  const coachFullName = displayNameFromContact(account) || "";
  const [loading, setLoading] = useState<boolean>(false);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const coachEmail = coachEmails[0];
  const { track } = useEmailSignature({
    userId: oid as string,
    email: coachEmail,
    memberId: aid as string,
  });
  const [isSecondaryLoading, setSecondaryLoading] = useState<boolean>(false);
  const getTrackProperties = (data: EmailContentType) => ({
    withEmailAlias: coachEmail !== data.fromEmail,
  });

  const send = async (
    data: EmailContentType,
    fromName: string,
    callback: () => void,
    draft = false
  ) => {
    const formattedEmails = getFormattedEmails(data);
    const recipient =
      data.recipients?.length === 1 && data.recipients[0]?.extra;
    const determinedGroupId =
      recipient && !groupId && recipient.type === "group" && recipient.id;

    try {
      const { subject, body, fromEmail } = data;
      await axios.post(`/api/v1/gmail/${oid}/send`, {
        email: coachEmail,
        fromEmail,
        body,
        draft,
        subject,
        fromName,
        ...formattedEmails,
        ...(groupId && { groupId }),
        ...(determinedGroupId && { groupId: determinedGroupId }),
      });
      toggleShow(false);
      track("inbox-coach-send-email", getTrackProperties(data));
      callback();
    } catch (e) {
      snackbar.showWarning(
        "Error occurred",
        "Sending message failed, please try again!"
      );
      track("inbox-coach-send-email-error", getTrackProperties(data));
    }
  };

  const onSubmit = async (data: EmailContentType) => {
    setLoading(true);
    await send(data, coachFullName, () => {
      snackbar.showMessage("Email sent", "You've successfully sent email");
      setLoading(false);

      if (onEmailSent) {
        onEmailSent();
      }
    });
  };

  const onSecondarySubmit = async (data: EmailContentType) => {
    setSecondaryLoading(true);
    await send(
      data,
      coachFullName,
      () => {
        snackbar.showMessage("Draft saved", "You've successfully saved draft");
        setSecondaryLoading(false);
        track("inbox-coach-send-draft-email", getTrackProperties(data));
      },
      true
    );
  };

  const handleCloseModal = () => toggleShow(false);

  const renderBottom = (
    <>
      <ToggleSignature className="mb-8 sm:mb-0 ml-4" />
      <SelectAliasesControlled
        className="mb-8 sm:mb-0 ml-4"
        email={coachEmail}
      />
    </>
  );

  const renderCloseButton = (
    <Button className="w-8 h-8 ml-4" onClick={handleCloseModal} circle smaller>
      <CloseIcon className=" text-lg" />
    </Button>
  );

  const handleToggle = () => {
    show ? setShowDiscardModal(true) : toggleShow;
  };

  return (
    <>
      <ModalWrappedForm
        show={show}
        toggleShow={handleToggle}
        onSubmit={onSubmit}
        maxWidth="max-w-3xl"
        title="New email"
        actionTitle="Send"
        secondaryActionTitle="Save Draft"
        onSecondarySubmit={onSecondarySubmit}
        hideHeaderSeperator={true}
        defaultValues={{ subject: "", body: "", email }}
        mode="onChange"
        isLoading={loading}
        isSecondaryLoading={isSecondaryLoading}
        onSubmitHeapEventName={heapEventName}
        onSecondarySubmitHeapEventName={secondaryHeapEventName}
        headerLeftContent={renderCloseButton}
        modalProps={{
          clearDialogStyle: true,
          dialogClassName:
            "fixed inset-0 bg-white p-4 overflow-visible overflow-y-auto  sm:absolute sm:w-11/12 sm:max-w-3xl sm:p-8 sm:mx-auto sm:mt-12 sm:rounded-2xl sm:max-h-[90%] sm:bottom-auto",
          dialogStyle: { maxHeight: "auto" },
        }}
        actionButtonsPosition="bottom"
        bottomChildren={renderBottom}
      >
        <ComposeEmail
          groupId={groupId}
          clientIds={clientIds}
          bodyFieldClassName="sticky-toolbar-t-8"
          hasSubject
        />
      </ModalWrappedForm>
      <UnsavedChangesModal
        show={showDiscardModal}
        toggleShow={setShowDiscardModal}
        onDiscard={handleCloseModal}
      />
    </>
  );
};

export default SendEmailModal;
