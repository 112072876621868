import React from "react";
import { secondsToMinutes } from "date-fns";
import { round } from "lodash";
import pluralize from "pluralize";

import { useAuth } from "@contexts/auth";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import PastStatusIcon from "@components/Icons/PastStatusIcon";
import LoadingSpinner from "@components/LoadingSpinner";

interface AppointmentsDashboardCardProps {
  className?: string;
  totalSeconds?: number;
  isStatic: boolean;
  isSelected: boolean;
  loading: boolean;
}

const AppointmentsDashboardCard: React.FC<AppointmentsDashboardCardProps> = ({
  className,
  totalSeconds,
  isStatic,
  isSelected,
  loading,
}) => {
  const { oid, aid } = useAuth();

  const printPastAppointmentsDuration = () => {
    if (!totalSeconds || loading) return <LoadingSpinner />;

    if (totalSeconds < 60) return <>Less than a minute</>;
    if (totalSeconds < 3600) {
      const minutes = secondsToMinutes(totalSeconds);
      return (
        <>
          {minutes} {pluralize("minute", minutes)}
        </>
      );
    }

    const hours = round(totalSeconds / 3600, 2);

    return (
      <>
        {hours} {pluralize("hour", hours)}
      </>
    );
  };

  if (isStatic) {
    return (
      <StaticDashboardCard
        className={className}
        href={`/api/gauth/${oid}?accountId=${aid}`}
        title="Appointments"
        number={1}
        description="See all your past and future appointments."
        buttonText="Connect calendar"
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/appointments"
      title="Appointments"
      content={printPastAppointmentsDuration()}
      description="Total past appointments"
      DescriptionIcon={PastStatusIcon}
      isLoading={!totalSeconds}
    />
  );
};

export default AppointmentsDashboardCard;
