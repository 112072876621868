import {
  GetTodaysViewActiveClientsRequest,
  GetTodaysViewActivePackagesRequest,
  GetTodaysViewSessionsByOutcomeRequest,
  GetTodaysViewSessionsBySchedulerRequest,
  TodaysViewActiveClientsApi,
  TodaysViewActivePackagesApi,
  TodaysViewSessionsByOutcomeApi,
  TodaysViewSessionsBySchedulerApi,
} from "@practice/sdk";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";

export type GetTodaysViewActivePackagesRequestQueryParams = Omit<
  GetTodaysViewActivePackagesRequest,
  "xRequestId" | "organizationId"
>;

export const useGetTodaysViewActivePackages = (
  props: GetTodaysViewActivePackagesRequestQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-todays-view-active-packages"
  );
  const { oid } = useAuth();
  const todaysViewApi = useSDKApi(TodaysViewActivePackagesApi);

  return useSWR(
    `organizations/${oid}/todays-view/active-packages?${JSON.stringify(props)}`,
    async () => {
      if (!oid) return;
      return todaysViewApi.getTodaysViewActivePackages({
        ...props,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );
};

export type GetTodaysViewActiveClientsRequestQueryParams = Omit<
  GetTodaysViewActiveClientsRequest,
  "xRequestId" | "organizationId"
>;

export const useGetTodaysViewActiveClients = (
  props: GetTodaysViewActiveClientsRequestQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-todays-view-active-clients"
  );
  const { oid } = useAuth();
  const todaysViewApi = useSDKApi(TodaysViewActiveClientsApi);

  return useSWR(
    `organizations/${oid}/todays-view/active-clients?${JSON.stringify(props)}`,
    async () => {
      if (!oid) return;
      return todaysViewApi.getTodaysViewActiveClients({
        ...props,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );
};

export type GetTodaysViewSessionsByOutcomeRequestQueryParams = Omit<
  GetTodaysViewSessionsByOutcomeRequest,
  "xRequestId" | "organizationId"
>;

export const useGetTodaysViewSessionsByOutcome = (
  props: GetTodaysViewSessionsByOutcomeRequestQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-todays-view-sessions-by-outcome"
  );
  const { oid } = useAuth();
  const todaysViewApi = useSDKApi(TodaysViewSessionsByOutcomeApi);

  const start = props.start ? new Date(props.start) : undefined;
  const end = props.end ? new Date(props.end) : undefined;

  const query = {
    ...props,
    ...(start && { start }),
    ...(end && { end }),
  };

  return useSWR(
    `organizations/${oid}/todays-view/sessions-by-outcome?${JSON.stringify(
      query
    )}`,
    async () => {
      if (!oid) return;
      return todaysViewApi.getTodaysViewSessionsByOutcome({
        ...query,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );
};

export type GetTodaysViewSessionsBySchedulerRequestQueryParams = Omit<
  GetTodaysViewSessionsBySchedulerRequest,
  "xRequestId" | "organizationId"
>;

export const useGetTodaysViewSessionsByScheduler = (
  props: GetTodaysViewSessionsBySchedulerRequestQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-todays-view-sessions-by-scheduler"
  );
  const { oid } = useAuth();
  const todaysViewApi = useSDKApi(TodaysViewSessionsBySchedulerApi);

  const start = props.start ? new Date(props.start) : undefined;
  const end = props.end ? new Date(props.end) : undefined;

  const query = {
    ...props,
    ...(start && { start }),
    ...(end && { end }),
  };

  return useSWR(
    `organizations/${oid}/todays-view/sessions-by-scheduler?${JSON.stringify(
      query
    )}`,
    async () => {
      if (!oid) return;
      return todaysViewApi.getTodaysViewSessionsByScheduler({
        ...query,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );
};
