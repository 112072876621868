import React, { FC, useState } from "react";
import { getWebflowVideo } from "api-services/definitions/webflow";
import { useApiGet } from "api-services/endpoints";

import { useIsMobile } from "@hooks/use-window-size";
import { videoIds } from "@lib/constants/webflow";

import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import AlarmOffIcon from "@components/Icons/AlarmOffIcon";
import InvoiceIcon from "@components/Icons/InvoiceIcon";
import LockIcon from "@components/Icons/LockIconUnfilled";
import PlayIcon from "@components/Icons/PlayIcon";
import PracticeIcon from "@components/Icons/PracticeIcon";
import Modal from "@components/Modals/Modal";
import VideoModal from "@components/Video/VideoModal/VideoModal";

export interface WelcomeModalProps {
  onDismiss: () => void;
}

const WelcomeModal: FC<WelcomeModalProps> = ({ onDismiss }) => {
  const isMobile = useIsMobile();

  const [showIntroVideoModal, setShowIntroVideoModal] = useState(false);

  const { data: video } = useApiGet(getWebflowVideo, {
    videoId: videoIds.welcomeModal,
  });

  const renderCTA = () => (
    <Button variant="primary" onClick={onDismiss}>
      Get started
    </Button>
  );

  const renderContent = () => (
    <>
      <div className="self-start bg-action-950 p-3 rounded-full">
        <PracticeIcon />
      </div>
      <h1 className="text-2xl leading-tight text-black-ink">
        Enjoy a 7-day free trial
      </h1>
      <p className="text-md text-grey-500">
        We’re so excited to welcome you to Practice! As you get started, here
        are a few important highlights.
      </p>
      <SmallBanner
        className="mb-auto"
        items={[
          {
            Icon: AlarmOffIcon,
            text: "We do not contact your clients without your explicit action.",
          },
          {
            Icon: InvoiceIcon,
            text: "We do not charge any additional fees on top of your subscription.",
          },
          {
            Icon: LockIcon,
            text: "We follow the highest privacy & security standards for all integrations.",
          },
        ]}
      />
    </>
  );

  const renderVideo = () => (
    <div
      className="flex flex-1 h-[196px] sm:h-auto sm:min-h-[500px] bg-[url('/images/img_intro_video@3x.png')] bg-cover bg-top bg-no-repeat rounded-2xl overflow-hidden group hover:cursor-pointer"
      onClick={() => setShowIntroVideoModal(true)}
      aria-label="Watch Practice intro video"
    >
      <div className="w-full h-full flex justify-center items-center group-hover:bg-black-ink/10">
        <div className="flex self-center items-center gap-2 p-4 rounded-full bg-grey-100 group-hover:bg-black-ink text-white group-hover:text-action-700">
          <PlayIcon />
          <span className="font-medium">Watch intro (2 min)</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        className="overflow-y-auto"
        size={isMobile ? "full" : "bigger"}
        show={!showIntroVideoModal}
        toggleShow={onDismiss}
        disableClose
      >
        <div className="flex flex-1 flex-col sm:flex-row gap-6 p-8">
          <div className="flex flex-1 flex-col sm:basis-5/12 gap-4 mb-auto sm:mb-0">
            {renderContent()}
            {!isMobile && renderCTA()}
          </div>

          <div className="flex flex-1 sm:basis-7/12">{renderVideo()}</div>
          {isMobile && renderCTA()}
        </div>
      </Modal>

      {showIntroVideoModal && (
        <VideoModal
          show
          toggleShow={() => setShowIntroVideoModal(false)}
          videoUrl={video?.youtubeUrl}
          autoplay
        />
      )}
    </>
  );
};

export default WelcomeModal;
