import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";
import { GroupType } from "types/db/client";

import { useAuth } from "@contexts/auth";
import { useContacts } from "@hooks/data/clients";
import useAccessType from "@hooks/use-access-type";
import useGroups from "@hooks/use-groups";
import useLabels from "@hooks/use-labels";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { GroupMemberType } from "@lib/shared-types";
import { sortByDisplayName, sortByTitle } from "@lib/sortBy";
import pluralHelper from "@lib/utils/pluralHelper";

import { Editor } from "@components/Editor/Editor";
import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import {
  CustomOptionType,
  ParentOptionType,
} from "@components/Form/SelectForm/types";
import TextFieldForm from "@components/Form/TextFieldForm";
import { ToggleFormComponent } from "@components/Form/Toggle";
import LabelIcon from "@components/Icons/LabelIcon";
import { LabelDot } from "@components/Labels";

interface ComposeEmailProps {
  clientIds?: string[];
  hasSubject?: boolean;
  bodyFieldClassName?: string;
  groupId?: string;
}

export const ComposeEmail: React.FC<ComposeEmailProps> = ({
  hasSubject,
  clientIds,
  bodyFieldClassName,
  groupId,
}) => {
  const {
    control,
    formState: { isSubmitting },
    register,
  } = useFormContext();

  const { oid } = useAuth();
  const { active: contacts } = useContacts();
  const { groups } = useGroups(oid);
  const { labels } = useLabels(oid!);
  const { hasElevatedAccess } = useAccessType();
  const canSendToLabel = hasElevatedAccess;
  const isDisabled = !!groupId;

  const renderSelectLabel = (title: string, sub: string, item?: any) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {item?.color && <LabelDot color={item.color} size="small" />}
          <h2 className="font-medium text-sm">{title}</h2>
        </div>
        {!isDisabled && <p className="text-xs text-grey-500 pt-0.5">{sub}</p>}
      </>
    );
  };

  const getContact = (id: string) => contacts?.find((c) => c.id === id);
  const getGroup = (gId: string) =>
    groups?.find((g: GroupType) => g.id === gId);

  const getGroupEmails = (group: GroupType) =>
    group.members.map((m: GroupMemberType) => getContact(m.clientId)?.email) ||
    [];

  const getClientInfo = (contact: Partial<ClientType> | ClientType) => {
    const clientName = displayNameFromContact(contact);
    const email = contact?.email;
    if (!email) return;
    return {
      label: renderSelectLabel(
        !contact.firstName ? "" : (clientName as string),
        email
      ),
      value: [email],
      extra: { search: clientName },
    };
  };

  const getGroupInfo = (group: GroupType) => {
    const emails = getGroupEmails(group);
    return {
      label: renderSelectLabel(
        group.title,
        pluralHelper(group.members?.length, "client")
      ),
      value: emails,
      extra: {
        search: group.title,
        id: group.id,
        type: "group",
      },
    };
  };

  const defaultValue = (
    groupId
      ? [getGroupInfo(getGroup(groupId))]
      : clientIds
      ? clientIds.map((clientId) => {
          return getClientInfo(
            clientId.includes("@")
              ? { email: clientId }
              : (getContact(clientId) as ClientType)
          );
        })
      : []
  ) as CustomOptionType[];

  const formattedContacts =
    sortByDisplayName(contacts)?.map((contact) => getClientInfo(contact)) || [];

  const formattedGroups =
    sortByTitle(groups)
      ?.map((group) => {
        if (group.status !== "active" || group.members.length < 2) return false;
        return getGroupInfo(group);
      })
      .filter(Boolean) || [];

  const formattedLabels =
    labels
      ?.map((label) => {
        const labelValues = contacts
          ?.map((c) => c.labels?.includes(label.id) && c.email)
          .filter(Boolean);
        if (labelValues?.length === 0) return null;
        return {
          label: renderSelectLabel(
            label.title,
            pluralHelper(labelValues?.length || 0, "client"),
            label
          ),
          value: labelValues,
          extra: {
            search: label.title,
            id: label.id,
            type: "label",
          },
        };
      })
      .filter(Boolean) || [];

  const parents: ParentOptionType[] = [
    {
      title: "Send to a label",
      subtitle: pluralHelper(labels?.length || 0, "label"),
      type: "label",
      icon: LabelIcon,
    },
  ];

  const formattedOptions = [
    ...formattedLabels,
    ...formattedGroups,
    ...formattedContacts,
  ] as CustomOptionType[];

  return (
    <div className="space-y-4 z-10 mt-6">
      <div className="flex flex-col sm:flex-row items-center w-full gap-2">
        <div className="w-full">
          <SearchableMultiSelectForm<CustomOptionType>
            defaultValue={defaultValue}
            options={formattedOptions}
            parents={canSendToLabel ? parents : undefined}
            label={groupId ? "Group" : "Client"}
            name="recipients"
            placeholder="Add recipient(s)"
            isDisabled={!!groupId}
            isSearchable
            checkBoxOption
            emptyMessage="No clients found, add or import clients first"
            type="clients"
            required
          />
        </div>

        <div className="flex gap-2 w-full sm:justify-center items-center pt-5 flex-1">
          <h5>Bcc</h5>
          <ToggleFormComponent name="bcc" control={control} size="small" />
        </div>
      </div>
      {hasSubject && (
        <TextFieldForm
          disabled={isSubmitting}
          label="Subject"
          register={register}
          name="subject"
        />
      )}
      <div>
        <div className="text-sm text-gray-500 mb-1">Message</div>
        <Controller
          control={control}
          name="body"
          rules={{
            required: true,
            validate: (v) => v !== "<div><br></div>",
          }}
          render={({ field: { value, onChange } }) => (
            <Editor
              className={classNames(
                "rounded-lg email-editor focus-within:bg-white focus-within:border-action-800",
                bodyFieldClassName
              )}
              bounds="#bounds"
              kind="email"
              value={value}
              onChange={onChange}
              readOnly={isSubmitting}
            />
          )}
        />
      </div>
    </div>
  );
};
