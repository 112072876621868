import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ActiveStatusIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#FAD89E" />
      <circle cx="12" cy="12" r="1.5" stroke="#FAD89E" />
      <path d="M13 11L13 5L8 13H11L11 19L16 11L13 11Z" fill="#CC8033" />
    </svg>
  );
};

export default ActiveStatusIcon;
