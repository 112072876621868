import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PastStatusIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#CCCCCC" />
      <path
        d="M6.15377 12.3536C5.95851 12.1583 5.95851 11.8417 6.15377 11.6465L10.3964 7.40381C10.5917 7.20855 10.9083 7.20855 11.1035 7.40381L11.8106 8.11092C12.0059 8.30618 12.0059 8.62276 11.8106 8.81802L9.62865 11L9.61914 11.0074L17.5073 11.0074C17.7835 11.0074 18.0073 11.2312 18.0073 11.5074L18.0073 12.5074C18.0073 12.7835 17.7835 13.0074 17.5073 13.0074L9.636 13.0074L11.8106 15.182C12.0059 15.3772 12.0059 15.6938 11.8106 15.8891L11.1035 16.5962C10.9083 16.7915 10.5917 16.7915 10.3964 16.5962L6.15377 12.3536Z"
        fill="#8A898E"
      />
    </svg>
  );
};

export default PastStatusIcon;
