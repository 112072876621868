import { isArray, omit, toUpper } from "lodash";
import { DateTime } from "luxon";
import Stripe from "stripe";

import { InvoiceType } from "@lib/data/schemas/invoice";

import { StripeInvoiceType } from "./types";

export const buildInvoiceObject = (
  paymentId: string,
  payment: InvoiceType,
  coupon?: string,
  paymentMethod?: string,
  metadata?: { [key: string]: string }
): StripeInvoiceType => {
  const now = DateTime.now();
  const dueDate = DateTime.fromJSDate(payment?.dueDate);
  const diff = dueDate.diff(now);
  const shouldSetDueDate = payment?.dueDate && diff.toMillis() > 0;

  const stripeInvoiceObject: StripeInvoiceType = {
    customer: payment.stripe.customer.id,
    ...(shouldSetDueDate &&
      !paymentMethod && { collection_method: "send_invoice" }),
    ...(paymentMethod && { default_payment_method: paymentMethod }),
    metadata: { origin: "Practice", paymentId, ...(metadata || {}) },
    description: payment.memo,
    footer: payment.footer,
    custom_fields: (payment.customFields || []).map(({ title, value }) => ({
      name: title,
      value: value,
    })),
    ...(coupon && { discounts: [{ coupon }] }),
  };

  if (shouldSetDueDate) {
    stripeInvoiceObject.due_date = Math.floor(dueDate.toSeconds());
  }
  return stripeInvoiceObject;
};

export const mapOldInvoiceToNew = (
  payment: InvoiceType | null | undefined
): InvoiceType | null => {
  if (!payment) return null;

  if (isArray(payment.items)) {
    return {
      ...payment,
      title:
        payment.memo ||
        `${
          payment.items.length === 1 ? `${payment.items[0].quantity}x ` : ""
        }${payment.items[0]?.description}${
          payment.items.length > 1
            ? ` and ${payment.items.length - 1} more`
            : ""
        }`,
    };
  }

  return {
    ...omit(payment, ["taxTypeId", "memo", "footer"]),
    tax: payment.total - payment.amount,
    memo: payment.memo || "",
    footer: payment.footer || "",
    items: [
      {
        description: payment.title,
        quantity: 1,
        unitAmount: payment.amount,
        itemAmount: payment.total,
        taxTypeId: payment.taxTypeId,
        taxAmount: payment.total - payment.amount,
      },
    ],
  };
};

export const copyInvoice = (
  paymentData: InvoiceType,
  stripeInvoice: Stripe.Invoice
) => {
  const originalPaymentData = mapOldInvoiceToNew(paymentData)!;
  const { couponId, discount } = paymentData;

  return {
    payeeId: originalPaymentData.payeeId,
    contactId: originalPaymentData.contactId,
    currency: originalPaymentData.currency,
    amount: originalPaymentData.amount,
    tax: originalPaymentData.tax,
    recurring: originalPaymentData.recurring,
    ...(originalPaymentData.memo && { memo: originalPaymentData.memo || "" }),
    ...(originalPaymentData.footer && {
      footer: originalPaymentData.footer || "",
    }),
    customFields: [...(originalPaymentData.customFields || [])],
    items: [...originalPaymentData.items].map((item) => ({
      ...item,
      quantity: Number(item.quantity),
    })),
    stripe: {
      ...originalPaymentData.stripe,
    },
    discount: stripeInvoice.discounts.length > 0 ? discount : 0,
    total: stripeInvoice.total,
    ...(stripeInvoice.discounts.length > 0 && { couponId }),
    status: originalPaymentData?.recurring ? "shared" : "viewed",
    ...(originalPaymentData.subscription && {
      subscription: originalPaymentData.subscription,
    }),
    invoice: stripeInvoice,
    ...(originalPaymentData.subcriptionId && {
      subscriptionId: originalPaymentData.subscriptionId,
    }),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

export const toUnits = (payment) => ({
  ...payment,
  amount: parseFloat(parseInt(payment.amount) / 100).toFixed(2),
  tax: parseFloat(parseInt(payment.tax) / 100).toFixed(2),
  discount: parseFloat(parseInt(payment.discount) / 100).toFixed(2),
  total: parseFloat(parseInt(payment.total) / 100).toFixed(2),
  items: payment.items.map((item) => ({
    ...item,
    unitAmount: parseFloat(parseInt(item.unitAmount) / 100).toFixed(2),
    itemAmount: parseFloat(parseInt(item.itemAmount) / 100).toFixed(2),
    taxAmount: parseFloat(parseInt(item.taxAmount) / 100).toFixed(2),
  })),
});

export const toCents = (payment) => ({
  ...payment,
  amount: payment.amount * 100,
  tax: payment.tax * 100,
  total: payment.total * 100,
  items: payment.items.map((item) => ({
    ...item,
    unitAmount: item.unitAmount * 100,
    itemAmount: item.itemAmount * 100,
    taxAmount: item.taxAmount * 100,
  })),
});

export const formatSubscriptionPlan = (
  subscription: Stripe.Subscription
): string => {
  const total = subscription.items.data.reduce(
    (prev, current) => prev + current.price.unit_amount / 100,
    0
  );
  const currency = toUpper(subscription.items.data[0].price.currency);
  const interval = subscription.items.data[0].price.recurring.interval;

  return `${total} ${currency} / ${interval}`;
};

export const isMemberSubscription = (
  subscription: Stripe.Subscription
): boolean => {
  return (
    !!subscription.metadata.member ||
    !!subscription.items.data[0].price.metadata.member
  );
};

/**
 * This is used to return an object with invoice error information to be
 * @param error the error object thrown by the function
 * */
export const getInvoiceBoundaryErrors = (error: Error) => {
  if (
    error.message.includes("You cannot combine currencies") ||
    error.message.includes("Nothing to invoice for customer")
  ) {
    const regExp = /with currency [a-zA-Z]{3}/;
    const executionResult = regExp.exec(error.message) ?? "";
    const currencyInfo =
      executionResult.length > 0
        ? executionResult[0].replace(/[a-zA-Z]{3}$/, (currency) =>
            currency.toUpperCase()
          )
        : "with a different currency";
    const errorMessage = `An invoice can not be created for you in this currency. You already have existing invoices in ${currencyInfo}.`;
    return {
      title: "Error creating invoice",
      description: errorMessage,
      handled: true,
    };
  }
};

/**
 * Remove the date from an invoice line item generated through the reconciliation
 * invoice system
 * */
export const getOnlyTitleFromLineItem = (title: string) =>
  title
    .replace(
      /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2},\s+\d{4}\b/,
      ""
    )
    .trim();
