import { FC } from "react";

import { useOrgPackages } from "@hooks/use-packages";
import { PackageType } from "@lib/data/schemas/packages";

import PackageIcon from "@components/Icons/PackageIcon";
import PackagePicker from "@components/PackagePicker";
import { PublicItemType as PackageItemType } from "@components/PublicProfile/PublicProfileSidebar/Pages/PublicItemCard";
import TypeIllustration from "@components/TypeIllustration";

import CustomDropdown from "./CustomDropdown";
import DefaultFilterDescriptor from "./DefaultFilterDescriptor";
import TodaysViewCard from "./TodaysViewCard";
import {
  useFetchTodaysViewActivePackages,
  useTodaysViewFiltersContext,
} from "./TodaysViewContext";
import TodaysViewFilterWrapper from "./TodaysViewFilterWrapper";

const ActivePackagesCard: FC = () => {
  const { data: response, isLoading } = useFetchTodaysViewActivePackages();
  const { filters, setFilters } = useTodaysViewFiltersContext();

  const packageId = filters?.packageId;
  const { getPackage, packages } = useOrgPackages();
  const { icon, title } = getPackage(packageId) ?? {};

  const metricData = response?.data;
  const metricPoint = metricData?.metricPoint;

  const filterDescription =
    metricPoint || metricPoint === 0 ? (
      <div className="flex items-center justify-center gap-1 w-full">
        {icon && <TypeIllustration slug={icon} height={24} width={24} />}
        <div className="font-medium truncate">{title}</div>
      </div>
    ) : (
      <DefaultFilterDescriptor Icon={PackageIcon} type="packages" />
    );

  const onPackageChange = (value: PackageType) => {
    setFilters({ packageId: value.id });
  };

  const hasPackages = packages?.length && packages?.length > 0;

  const customFilter = hasPackages ? (
    <TodaysViewFilterWrapper
      showReset={!!packageId}
      onReset={() => setFilters({ packageId: undefined })}
    >
      <PackagePicker
        onPackageChange={onPackageChange}
        label={null}
        customPlaceholder={<CustomDropdown />}
        packages={packages as PackageItemType[]}
        className="!mt-0 shadow-none"
        customMenuClassNames="!absolute !w-72 !mt-2"
      />
    </TodaysViewFilterWrapper>
  ) : null;

  return (
    <TodaysViewCard
      title="Active packages"
      metricData={metricData}
      tooltipCopy="Clients actively being served in packages, also includes paused and locked packages."
      filterDescription={filterDescription}
      customFilter={customFilter}
      isLoading={isLoading}
      totalId="active-packages-total"
    />
  );
};

export default ActivePackagesCard;
