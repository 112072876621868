import { FC } from "react";

import { Button } from "@components/Button";
import VideoThumbnail from "@components/Video/VideoThumbnail";

export interface GetStartedVideoCardProps {
  title: string;
  description?: string;
  thumbnailUrl: string;
  length?: number;
  onClickWatch: () => void;
  onClickDismiss: () => void;
}

const GetStartedVideoCard: FC<GetStartedVideoCardProps> = ({
  title,
  description,
  thumbnailUrl,
  length,
  onClickWatch,
  onClickDismiss,
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-6 p-6 bg-grey-950 rounded-2xl">
      <div className="flex-1 flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <h3 className="text-xl leading-tight font-medium">{title}</h3>
          {description && <p className="text-grey-500">{description}</p>}
        </div>
        <div className="flex gap-2 mt-auto">
          <Button variant="primary" small onClick={onClickWatch}>
            Watch demo
          </Button>
          <Button variant="white" small onClick={onClickDismiss}>
            Not now
          </Button>
        </div>
      </div>
      <VideoThumbnail
        title={title}
        thumbnailUrl={thumbnailUrl}
        length={length}
        onClick={onClickWatch}
      />
    </div>
  );
};

export default GetStartedVideoCard;
