import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ChevronLeftIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58581 5.99997L3.64645 3.06061C3.45119 2.86535 3.45119 2.54877 3.64645 2.35351L4.35355 1.6464C4.54882 1.45114 4.8654 1.45114 5.06066 1.6464L9.06069 5.64643C9.25595 5.84169 9.25595 6.15828 9.06069 6.35354L5.06066 10.3535C4.8654 10.5488 4.54881 10.5488 4.35355 10.3535L3.64645 9.6464C3.45119 9.45113 3.45119 9.13455 3.64645 8.93929L6.58581 5.99997Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={12} height={12} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronLeftIcon;
