import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2063 6.4064L15.9633 4.90781L16.8023 3.09229L23.094 5.99973L20.1866 12.2915L18.3711 11.4525L19.8235 8.30933C14.639 9.86584 11 14.659 11 20.1804V21L9 21.0001C9 17.1341 5.86599 14.0001 2 14.0001V12.0001C5.18055 12.0001 7.97576 13.6499 9.57652 16.1404C10.9225 11.5347 14.5169 7.82942 19.2063 6.4064Z"
        fill="evenodd"
      />
    </svg>
  );
};

export default Icon;
