import { FC } from "react";

interface ResetFilterProps {
  onReset: () => void;
  children: React.ReactNode;
  showReset: boolean;
}

const TodaysViewFilterWrapper: FC<ResetFilterProps> = ({
  onReset,
  children,
  showReset,
}) => {
  return (
    <div className="relative">
      {showReset && (
        <div
          onClick={onReset}
          className="text-xs text-grey-500 absolute top-1.5 right-7 hover:cursor-pointer hover:underline"
        >
          Reset
        </div>
      )}
      {children}
    </div>
  );
};

export default TodaysViewFilterWrapper;
