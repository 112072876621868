import React from "react";
import classNames from "classnames";

interface StepIndicatorProps {
  step: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ step }) => {
  return (
    <div className="h-1 w-[52px] flex">
      <div
        className={classNames(
          "w-1/3 rounded-l-full mr-0.5",
          step === 2 || step === 3 ? "bg-action-600" : "bg-grey-900"
        )}
      />
      <div
        className={classNames(
          "w-1/3 mr-0.5",
          step === 3 ? "bg-action-600" : "bg-grey-900"
        )}
      />
      <div className="w-1/3 rounded-r-full bg-grey-900" />
    </div>
  );
};

export default StepIndicator;
