import React from "react";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import ActiveStatusIcon from "@components/Icons/ActiveStatusIcon";
import LoadingSpinner from "@components/LoadingSpinner";

interface ClientsDashboardCardProps {
  className?: string;
  clientsNumbers: {
    active?: number;
    total?: number;
  };
  isStatic: boolean;
  isSelected: boolean;
  isLoadingClients?: boolean;
}

const ClientsDashboardCard: React.FC<ClientsDashboardCardProps> = ({
  className,
  clientsNumbers,
  isStatic,
  isSelected,
  isLoadingClients,
}) => {
  const printContent = () => {
    if (!clientsNumbers.active || isLoadingClients)
      return <LoadingSpinner variant="transparent" />;

    return (
      <>
        {clientsNumbers.active}
        <span className="text-grey-500 text-xl ml-2">
          /{clientsNumbers.total}
        </span>
      </>
    );
  };

  if (isStatic && !isLoadingClients) {
    return (
      <StaticDashboardCard
        className={className}
        href="/contacts/import"
        title="Clients"
        number={2}
        description="Track, manage and organize your client list."
        buttonText="Add Clients"
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/contacts"
      title="Contacts"
      content={printContent()}
      description="Active clients"
      DescriptionIcon={ActiveStatusIcon}
      isLoading={isLoadingClients}
    />
  );
};

export default ClientsDashboardCard;
