import React from "react";
import classNames from "classnames";
import Link from "next/link";

import LoadingSpinner from "@components/LoadingSpinner";

interface ActiveDashboardCardProps {
  className?: string;
  href?: string;
  onClick?: () => void;
  title: string;
  content: React.ReactNode;
  description: string;
  DescriptionIcon: any;
  isLoading?: boolean;
}

const ActiveDashboardCard: React.FC<ActiveDashboardCardProps> = ({
  className,
  href,
  onClick,
  title,
  content,
  description,
  DescriptionIcon,
  isLoading = false,
}) => {
  const renderContent = (
    <div
      onClick={onClick}
      className={classNames(
        className,
        "bg-grey-950 border border-grey-900 hover:bg-action-900 focus:bg-action-700 active:bg-action-700 rounded-2xl cursor-pointer p-4 "
      )}
    >
      <div className="hidden sm:flex items-center">
        <h3 className="text-grey-500 text-sm leading-5">{title}</h3>
      </div>
      <div className="flex flex-col items-center justify-center h-[84px] sm:h-[124px] md:h-[156px]">
        <div className="text-xl sm:text-2xl md:text-3xl font-medium leading-9 mb-2">
          {isLoading ? (
            <LoadingSpinner variant="transparent" className="h-9" />
          ) : (
            content
          )}
        </div>
        <div className="flex items-center justify-center sm:flex-col-reverse lg:flex-row">
          <DescriptionIcon className="mr-2" />
          <p className="text-grey-500 text-sm font-normal leading-5">
            {description}
          </p>
        </div>
      </div>
    </div>
  );

  const renderWithLink = href && (
    <Link href={href} legacyBehavior>
      {renderContent}
    </Link>
  );

  return renderWithLink || renderContent;
};

export default ActiveDashboardCard;
