import React from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useUser from "@hooks/use-user";

import { Button } from "@components/Button";
import ViewOffIcon from "@components/Icons/ViewOffIcon";

interface StaticDashboardCardProps {
  className?: string;
  href: string;
  title: string;
  number: number;
  description: string;
  buttonText?: string;
  isSelected?: boolean;
}

const StaticDashboardCard: React.FC<StaticDashboardCardProps> = ({
  className,
  href,
  title,
  number,
  description,
  buttonText,
  isSelected = false,
}) => {
  const { oid } = useAuth();
  const { update } = useUser(oid);

  const processHREF = () => {
    window.location = href;
  };

  return (
    <div
      className={classNames(
        className,
        "border rounded-2xl p-4",
        isSelected ? " border-action-600" : "border-dashed border-grey-900"
      )}
    >
      <div className="hidden sm:flex items-center justify-between">
        <div className="flex items-center">
          <div
            className={classNames(
              "flex items-center justify-center w-6 h-6 rounded-full mr-2",
              isSelected ? "bg-action-600" : "bg-grey-800 "
            )}
          >
            <p className="text-white text-xs font-medium">{number}</p>
          </div>
          <h3
            className={classNames(
              "text-sm font-medium leading-5",
              isSelected ? "text-black-ink" : "text-grey-500"
            )}
          >
            {title}
          </h3>
        </div>
        {isSelected && title === "Payments" && (
          <button
            onClick={() =>
              update({
                dashboard: { payments: "dismissed" },
                updatedAt: new Date(),
              })
            }
            className="inline-flex items-center justify-center text-gray-500 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            aria-label="Dismiss payments"
          >
            <ViewOffIcon className="text-grey-500" />
          </button>
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-[34px] sm:h-[74px] md:h-[106px]">
        <p className="text-grey-500 leading-5">{description}</p>
      </div>
      {buttonText && (
        <Button
          className="w-full mt-4 sm:mt-0"
          primary={isSelected}
          disabled={!isSelected}
          onClick={() => processHREF()}
        >
          <a target="_blank">{buttonText}</a>
        </Button>
      )}
    </div>
  );
};

export default StaticDashboardCard;
