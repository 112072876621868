import React, { FC } from "react";
import { useRouter } from "next/router";

import useOutcomes from "@hooks/use-outcomes";
import usePersistentState from "@hooks/use-persistent-state";
import { OutcomeType } from "@lib/data/schemas/outcome";

import Flag3Icon from "@components/Icons/Flag3Icon";
import OutcomeIcon from "@components/Outcomes/OutcomeIcon";
import OutcomePicker from "@components/Outcomes/OutcomePicker";

import CustomDropdown from "./CustomDropdown";
import DefaultFilterDescriptor from "./DefaultFilterDescriptor";
import TodaysViewCard from "./TodaysViewCard";
import {
  useFetchTodaysViewSessionsByOutcome,
  useTodaysViewFiltersContext,
} from "./TodaysViewContext";
import TodaysViewFilterWrapper from "./TodaysViewFilterWrapper";

interface SessionsByOutcomeCardProps {}

const SessionsByOutcomeCard: FC<SessionsByOutcomeCardProps> = () => {
  const { data: response, isLoading } = useFetchTodaysViewSessionsByOutcome();
  const { persistentValue: value, persistentSetValue: setValue } =
    usePersistentState("appointmentsListConfiguration");
  const metricData = response?.data;

  const { filters, setFilters } = useTodaysViewFiltersContext();
  const router = useRouter();

  const outcomeId = filters?.outcomeId;
  const { outcomes: orgOutcomes } = useOutcomes();
  const noOutcomeOption = {
    id: "none",
    icon: "ic_complete",
    color: {
      foreground: "#6B7280",
    },
    title: "No outcome",
  };

  const outcomes = [noOutcomeOption, ...(orgOutcomes ?? [])];
  const outcome = outcomes?.find((outcome) => outcome.id === outcomeId);
  const hasOutcomes = orgOutcomes?.length && orgOutcomes?.length > 0;

  const onOutcomeChange = (value: OutcomeType) => {
    setFilters({ outcomeId: value.id });
  };

  const onClick = () => {
    setValue({
      ...value,
      filtersMap: {
        ...value.filtersMap,
        withoutOutcome: true,
      },
    });
    router.push({
      pathname: "/appointments",
      query: {
        tab: "past",
      },
    });
  };

  const filterDescription = outcome ? (
    <div className="flex items-center justify-center gap-1 w-full">
      <OutcomeIcon
        outcome={outcome}
        withLabel
        iconClassName="w-4 h-4"
        className="p-1 !flex-auto"
      />
    </div>
  ) : (
    <DefaultFilterDescriptor type="outcomes" Icon={Flag3Icon} />
  );

  const customFilter = hasOutcomes ? (
    <TodaysViewFilterWrapper
      showReset={!!outcomeId}
      onReset={() => setFilters({ outcomeId: undefined })}
    >
      <OutcomePicker
        onOutcomeChange={onOutcomeChange}
        placeholder={<CustomDropdown />}
        value={outcome}
        outcomes={outcomes}
      />
    </TodaysViewFilterWrapper>
  ) : null;

  return (
    <TodaysViewCard
      title="Sessions by outcome"
      metricData={metricData}
      tooltipCopy="The number of sessions with or without an outcome."
      filterDescription={filterDescription}
      customFilter={customFilter}
      isLoading={isLoading}
      totalId="sessions-by-outcome-total"
      onClick={outcome?.id === "none" ? onClick : undefined}
    />
  );
};

export default SessionsByOutcomeCard;
