import { FC } from "react";

import DropdownIcon from "@components/Icons/DropdownIcon";

const CustomDropdown: FC = () => {
  return (
    <div className="rounded-md text-sm font-medium bg-grey-950 flex items-center border hover:cursor-pointer">
      <DropdownIcon className="inline w-5 h-5 text-grey-500" />
    </div>
  );
};

export default CustomDropdown;
