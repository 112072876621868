import { useMemo } from "react";
import indexOf from "lodash/indexOf";
import sortBy from "lodash/sortBy";
import toString from "lodash/toString";

import { useCollection } from "@contexts/data";
import { CurrencyType } from "@lib/data/schemas/currency";

const topCurrencies = ["USD", "CAD", "EUR"];

export const useCurrencies = (): { currencies: CurrencyType[] } => {
  const { data } = useCollection("currencies", {
    baseCollection: true,
    orderByCreatedAt: false,
  });

  const currencies = useMemo(() => {
    return sortBy(data, (currency) => {
      const index = indexOf(topCurrencies, currency.code);
      return index >= 0 ? toString(index) : currency.code;
    });
  }, [data]);

  return { currencies };
};
