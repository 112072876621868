import { FC } from "react";

import PlayIcon from "@components/Icons/PlayIcon";

export interface VideoThumbnailProps {
  title: string;
  thumbnailUrl: string;
  length?: number;
  onClick: () => void;
}

const VideoThumbnail: FC<VideoThumbnailProps> = ({
  title,
  thumbnailUrl,
  length,
  onClick,
}) => {
  return (
    <div
      className="relative flex-grow-0 max-w-[300px] rounded-md overflow-hidden group hover:cursor-pointer"
      onClick={onClick}
      aria-label={`Watch ${title} video`}
    >
      <img
        className="block"
        src={thumbnailUrl}
        width={300}
        alt={`Video thumbnail for ${title}`}
      />
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center group-hover:bg-black-ink/10">
        <div className="flex self-center items-center gap-2 py-2 pl-2 pr-3 rounded-full bg-grey-100 group-hover:bg-black-ink text-white group-hover:text-action-700">
          <PlayIcon />
          <span className="text-sm font-medium leading-none select-none">
            {length ? `${length} min` : "Watch"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VideoThumbnail;
