import { FC, useMemo } from "react";
import classNames from "classnames";
import moment from "moment";

import { momentDate } from "@lib/utils/todos";

import Dropdown from "@components/Dropdown";
import { PastDatesPicker } from "@components/Form/DatePicker";
import ArrowIcon from "@components/Icons/ArrowIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import ChevronRightIcon from "@components/Icons/ChevronRight";

import { useTodaysViewFiltersContext } from "./TodaysViewContext";
import { PersistentValueType } from "./utils";

const TodaysViewDateFilter: FC = () => {
  const { filters, setFilters } = useTodaysViewFiltersContext();
  const { start, end, dateRange } = filters;

  const getMomentDate = (date) => (date ? moment(date) : undefined);
  const momentStart = getMomentDate(start);
  const momentEnd = getMomentDate(end);

  const dateOptions = useMemo(() => {
    const today = moment();
    const getPreviousDate = (days: number) => moment().subtract(days, "days");
    const previous30Days = getPreviousDate(30);
    const previous90Days = getPreviousDate(90);
    return [
      {
        label: "All time",
        value: {
          start: undefined,
          end: undefined,
        },
        key: "all-time",
      },
      {
        label: "Last 30 days",
        value: {
          start: previous30Days,
          end: today,
        },
        key: "last-30-days",
      },
      {
        label: "Last 90 days",
        value: {
          start: previous90Days,
          end: today,
        },
        key: "last-90-days",
      },
    ];
  }, []);

  const selected = useMemo(() => {
    const custom = {
      label: "Custom",
      value: {
        start,
        end,
      },
    };
    if (dateRange) {
      return dateOptions.find((option) => option.key === dateRange) ?? custom;
    }
    if (!start) {
      return dateOptions[0];
    }

    const isSameDate = (days: number) =>
      momentDate(start)?.isSame(moment().subtract(days, "days"), "day");

    if (isSameDate(30)) return dateOptions[1];
    if (isSameDate(90)) return dateOptions[2];
    return custom;
  }, [dateOptions, dateRange, end, start]);

  const renderDatePicker = (isStart: boolean) => {
    const momentDate = isStart ? momentStart : momentEnd;
    const placeHolder = momentDate
      ? momentDate?.format("MMM DD")
      : isStart
      ? "Start"
      : "End";

    const isOutsideRange = (date) => {
      if (isStart) return momentEnd ? momentEnd.isBefore(date, "day") : false;
      return momentStart ? momentStart.isAfter(date, "day") : false;
    };

    return (
      <div className="hidden-date-input">
        <PastDatesPicker
          inputIconPosition="before"
          CustomInputIcon={() => (
            <div className="border px-4 py-1 rounded-md flex">
              <div className="text-grey-500 text-xs">{placeHolder}</div>
            </div>
          )}
          date={momentDate ?? moment()}
          isOutsideRange={isOutsideRange}
          onChange={(e) => {
            const date = e.target.value;
            setFilters({
              ...(isStart && { start: date }),
              ...(!isStart && { end: date }),
              dateRange: "custom",
            });
          }}
          displayYear={true}
          overrideMobilePortal
          placeholder=""
          showClearDate={false}
        />
      </div>
    );
  };

  return (
    <Dropdown
      buttonClasses="!border-none"
      menuClasses="!overflow-visible !mt-2"
      content={
        <div className="rounded-md text-sm font-medium bg-grey-950 py-1.5 px-2 flex items-center gap-2">
          {selected?.label}
          <ChevronRightIcon className="transform text-grey-500 rotate-90" />
        </div>
      }
    >
      <div className="text-sm w-48 bg-white rounded-xl">
        {dateOptions.map((option, index) => (
          <div
            key={option.label}
            className={classNames(
              "hover:bg-action-900 cursor-pointer py-2 px-3 flex items-center justify-between",
              index === 0 && "rounded-t-xl"
            )}
            onClick={() => {
              setFilters({
                start: option.value.start,
                end: option.value.end,
                dateRange: option.key as PersistentValueType["dateRange"],
              });
            }}
          >
            {option.label}
            {selected?.label === option.label && (
              <CheckIcon className="h-5 w-5" />
            )}
          </div>
        ))}
        <div className="bg-grey-950 py-2 px-3 border-t ignore-click rounded-b-xl">
          <h5 className="text-grey-500 text-xs pb-1">Custom dates</h5>
          <div className="flex items-center justify-between">
            {renderDatePicker(true)}
            <ArrowIcon className="h-5 w-5 text-grey-500 transform -scale-x-1" />
            {renderDatePicker(false)}
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default TodaysViewDateFilter;
