import React, { createContext, ReactNode, useState } from "react";
import { noop } from "lodash";
import { useSWRConfig } from "swr";

import {
  useGetTodaysViewActiveClients,
  useGetTodaysViewActivePackages,
  useGetTodaysViewSessionsByOutcome,
  useGetTodaysViewSessionsByScheduler,
} from "@hooks/use-todays-view";

import {
  PersistentValueType as TodaysViewFilterType,
  usePersistentTodaysViewFilter,
} from "./utils";

interface TodaysViewFiltersContextValue {
  filters: TodaysViewFilterType;
  setFilters: (filters: TodaysViewFilterType) => void;
  refresh: () => void;
}

export const TodaysViewFilterContext =
  createContext<TodaysViewFiltersContextValue>({
    filters: {
      memberId: undefined,
      start: undefined,
      end: undefined,
      packageId: undefined,
      labelId: undefined,
      schedulerId: undefined,
      outcomeId: undefined,
    },
    setFilters: noop,
    refresh: noop,
  });

export const useTodaysViewFiltersContext = () => {
  return React.useContext(TodaysViewFilterContext);
};

export const TodaysViewFilterProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <InnerContext>{children}</InnerContext>;
};

export const useFetchTodaysViewActivePackages = () => {
  const { filters } = useTodaysViewFiltersContext();
  const { memberId, packageId } = filters;
  return useGetTodaysViewActivePackages({ memberId, packageId });
};

export const useFetchTodaysViewActiveClients = () => {
  const { filters } = useTodaysViewFiltersContext();
  const { memberId, labelId } = filters;
  return useGetTodaysViewActiveClients({ memberId, labelId });
};

export const useFetchTodaysViewSessionsByOutcome = () => {
  const { filters } = useTodaysViewFiltersContext();
  const { memberId, start, end, outcomeId } = filters;
  return useGetTodaysViewSessionsByOutcome({ memberId, start, end, outcomeId });
};

export const useFetchTodaysViewSessionsByScheduler = () => {
  const { filters } = useTodaysViewFiltersContext();
  const { memberId, start, end, schedulerId } = filters;
  return useGetTodaysViewSessionsByScheduler({
    memberId,
    start,
    end,
    schedulerId,
  });
};

export const InnerContext: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { mutate, cache } = useSWRConfig();

  const { persistentTodaysView, setPersistentTodaysView } =
    usePersistentTodaysViewFilter();

  const {
    memberId,
    start,
    end,
    dateRange,
    packageId,
    labelId,
    outcomeId,
    schedulerId,
  } = persistentTodaysView ?? {};

  const [filters, setFilters] = useState<TodaysViewFilterType>({
    ...(memberId && { memberId }),
    ...(start && { start }),
    ...(end && { end }),
    ...(dateRange && { dateRange }),
    ...(packageId && { packageId }),
    ...(labelId && { labelId }),
    ...(outcomeId && { outcomeId }),
    ...(schedulerId && { schedulerId }),
  });

  const handleChangeFilter = (newFilters: TodaysViewFilterType) => {
    setPersistentTodaysView({
      ...persistentTodaysView,
      ...newFilters,
    });
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const refresh = () => {
    const promises: Promise<void>[] = [];
    for (const key of cache.keys()) {
      if (key.includes("/todays-view")) {
        promises.push(mutate(key));
      }
    }
    return Promise.all(promises);
  };

  const value: TodaysViewFiltersContextValue = {
    filters,
    setFilters: handleChangeFilter,
    refresh,
  };

  return (
    <TodaysViewFilterContext.Provider value={value}>
      {children}
    </TodaysViewFilterContext.Provider>
  );
};
